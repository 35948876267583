// --------------------------------------------------
// REACT
// --------------------------------------------------
import React, { useState, useEffect } from 'react';

// --------------------------------------------------
// HELPERS
// --------------------------------------------------
import { graphql, Link } from 'gatsby';
import { smartypants } from '../helpers/helpers';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import Layout from '../components/layout';
import SEO from '../components/seo';
import Obfuscate from 'react-obfuscate';
import PostFeed from '../components/post-feed/post-feed';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './index.module.scss';


const BlogIndex = ( { data, location } ) => {
	const siteTitle = data.site.siteMetadata.title;
	const posts = data.allPortfolioJson.edges;
	const [cvExists, setCvExists] = useState(false);
	const pathToCv = '/scott-rankin_graphic-designer.pdf';

	useEffect(() => {
		const checkCvExists = async () => {
		  try {
			const response = await fetch(`${__PATH_PREFIX__}${pathToCv}`);
			setCvExists(response.ok);
		  } catch (error) {
			setCvExists(false);
		  }
		};

		checkCvExists();
	  }, []);

	return (
		<Layout location={location} title={siteTitle} colophon={data.site.siteMetadata.colophon}>
			<SEO title={siteTitle} isHome={true} />
			<dl className={styles.defnList}>
				<div className={`${styles.defnListItem} ${styles.bio}`}>
					<dt>About</dt>
					<dd className={`${styles.defnListItemContent} ${styles.bio}`}>
						{data.site.siteMetadata.bio.map( ( bio, index ) => (
							<p key={index} dangerouslySetInnerHTML={{ __html: smartypants( bio ) }}></p>
						) )}
					</dd>
				</div>

				<div className={`${styles.defnListItem} ${styles.otherActivity}`}>
					<dt>Other Activity</dt>
					<dd className={`${styles.defnListItemContent} ${styles.otherActivity}`}>
						<Link to='/illustration'><span>Illustration</span></Link>
						<Link to='/typography'><span>Typography</span></Link>
						{cvExists &&
						<a href={pathToCv} target='_blank'>
							<span>CV</span>
						</a>}
					</dd>
				</div>

				<div className={`${styles.defnListItem} ${styles.contact}`}>
					<dt>Contact</dt>
					<dd className={`${styles.defnListItemContent} ${styles.contact}`}>
						<dl>
							<dt>Email</dt>
							<dd>
								<Obfuscate email={data.site.siteMetadata.contact.email} />
							</dd>
							<dt>Phone</dt>
							<dd>
								<Obfuscate tel={data.site.siteMetadata.contact.phone} />
							</dd>
						</dl>
					</dd>
				</div>

				<div className={`${styles.defnListItem} ${styles.selectedWork}`}>
					<dt>Selected Work</dt>
					<dd className={`${styles.defnListItemContent} ${styles.selectedWork}`}><PostFeed posts={posts}/></dd>
				</div>
			</dl>
		</Layout>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				bio
				contact {
					email
					phone
				}
				colophon {
					heading
					content
				}
			}
		}
		allPortfolioJson(
			sort: { fields: date, order: DESC }
			filter: { fields: { slug: { ne: "/zzz-kitchen-sink/" } } }
		) {
			edges {
				node {
					fields {
						slug
					}
					date(formatString: "YYYY")
					title
					description
					category
					thumbnail {
						index {
							file {
								...projectImage
							}
						}
					}
				}
			}
		}
	}
`;

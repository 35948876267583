// --------------------------------------------------
// REACT
// --------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------
import PostFeedItem from '../post-feed-item/post-feed-item';

// --------------------------------------------------
// STYLES
// --------------------------------------------------
import styles from './post-feed.module.scss';


const PostFeed = ( { posts } ) => (
	Array.isArray( posts ) && posts.length ? (
		<ul className={styles.list}>
			{posts.map( ( { node } ) => (
				<li className={styles.listItem} key={node.fields.slug}>
					<PostFeedItem post={node}/>
				</li>
			) )}
		</ul>
	) : null
);

PostFeed.propTypes = {
	posts: PropTypes.arrayOf( PropTypes.object ).isRequired,
};

export default PostFeed;
